export default function ({ app, store, query }) {
  if (query.gt_origen) {
    app.$cookiz.set('gt_origen', query.gt_origen, {
      path: '/',
      maxAge: 60 * 60 * 24 * 30
    })
  }

  store.dispatch('countries/setCountry')
}
